var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"max-width":"40rem"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{attrs:{"icon":"","exact":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v("Transaction")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","loading":_vm.saving},on:{"click":function($event){return _vm.update_transaction()}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1),_c('v-btn',{attrs:{"color":"#c00000","icon":"","loading":_vm.deleting},on:{"click":function($event){return _vm.delete_transaction()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),(_vm.transaction)?_c('v-card-text',[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Description"},model:{value:(_vm.transaction.description),callback:function ($$v) {_vm.$set(_vm.transaction, "description", $$v)},expression:"transaction.description"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Amount","type":"number","prefix":_vm.transaction.currency},model:{value:(_vm.transaction.amount),callback:function ($$v) {_vm.$set(_vm.transaction, "amount", _vm._n($$v))},expression:"transaction.amount"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Date","value":new Date(_vm.transaction.time).toLocaleDateString(),"readonly":""}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Account: "),_c('router-link',{attrs:{"to":{
            name: 'account',
            params: { accountId: _vm.transaction.account_id },
          }}},[_vm._v(" "+_vm._s(_vm.accounts.find((a) => a.id === _vm.transaction.account_id)?.name || _vm.transaction.account_id)+" ")])],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Categories")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-2",attrs:{"to":{ name: 'transaction_categories' },"outlined":""}},[_vm._v(" Manage ")]),_c('AddCategoryDialog',{attrs:{"transactionId":String(_vm.transactionId),"accountId":String(_vm.accountId)},on:{"categoryAdded":function($event){return _vm.get_transaction()}}})],1),_c('v-card-text',_vm._l((_vm.transaction.categories),function(category){return _c('v-chip',{key:category.id,staticClass:"mb-2 mr-2",attrs:{"cols":"auto","close":"","to":{
                name: 'transaction_category',
                params: { categoryId: category.id },
              }},on:{"click:close":function($event){return _vm.removeCategory(category.id)}}},[_vm._v(_vm._s(category.name))])}),1)],1)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }